<template>
     <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
         <el-form ref="form" label-width="100px">
             <el-form-item size="small" label="上级名称:" required>
                 <p v-text="editData.parentName"></p>
             </el-form-item>

             <el-form-item size="small" label="机构代码:" required>
                 <p v-text="editData.departmentCode"></p>
             </el-form-item>

             <el-form-item size="small" label="机构名称:" required >
                 <el-input v-model="editData.departmentName" style="width: 425px;" maxlength="6" show-word-limit></el-input>
             </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="updateDepartment">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
     </el-dialog>
 </template>
<script>

  import {updateDepartment} from '../../../api/sys/department'

  export default {
    data () {
      return {
        show: false,
      }
    },

    props: {
      editData: Object,
      editVisible: Boolean
    },
    methods: {
      closeEvent () {
        this.$emit('closeEdit')
      },
      async updateDepartment () {
        let data = {
            id: this.editData.id,
            departmentName: this.editData.departmentName,
        };
        console.log(data)
        let res = await updateDepartment(data);
        if (res.code==200){
              this.$message({
                showClose: true,
                message: res.message,
                type: 'success'
              });
              this.$emit('editSuccess')
          }else{
              this.$message({
                showClose: true,
                message: res.message,
                type: 'error'
              });
          }
      }
    }
  }
</script>

<style scoped>

</style>
