<template>
  <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
      <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="用户名:" required >
          <el-input v-model="item.loginName" maxlength="12" style="width: 425px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="姓名:" required >
          <el-input v-model="item.name" maxlength="8" style="width: 425px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="邮箱:" required>
          <el-input v-model="item.email" maxlength="24" style="width: 425px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="组织名称:" required >
            <el-cascader
                    style="width: 425px"
                :options="pOptions"
                v-model="id"
                value ="code"
                :show-all-levels="false"
                change-on-select
            ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="updateUser">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
    </el-dialog></template>
<script>
  import {saveUser,getDepartmentList,getAllParentDepartmentId} from '../../../api/sys/user';
  import {dataFormat} from '../../../utils/main';

  export default {
    data () {
      return {
        show: false,
        item: this.editItem,
//        roleId:  this.editItem['roleId'],
        pOptions: [],
        id: [],
        code:''
      }
    },
    props: {
      departmentId:String,
      editItem: Object,
      /*roleItem: Array,*/
      options: Object,
      editVisible: Boolean
    },
      async created() {
          this.departmentList();
          this.getAllParentDepartmentId();
      },
    methods: {
      closeEvent () {
        this.$emit('closeEdit')
      },
        //获取组织机构
        async departmentList() {
            let res = await getDepartmentList()
            let data = dataFormat(this.options,res)
            this.pOptions = data.list;
        },
        async getAllParentDepartmentId() {
            let res = await getAllParentDepartmentId(this.departmentId);
            this.id = res;
        },
      async updateUser () {
        if (!this.item.loginName || !this.item.email || !this.item.name) {
            return;
        }

            let id =  this.id[this.id.length-1];
          let code = this.code[this.code.length-1];
          let data = {
            id: this.item.id,
            loginName: this.item.loginName,
            email: this.item.email,
              orgId: id,
            departmentCode:code,
            name: this.item.name
        };
        let res = await saveUser(data);
        if (res.code==200){
            this.$message({
              showClose: true,
              message: res.message,
              type: 'success'
            });
            this.$emit('editSuccess')
        }else{
            this.$message({
              showClose: true,
              message: res.message,
              type: 'error'
            });
        }
      }
    }
  }
</script>

<style scoped>

</style>
