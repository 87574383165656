<template>
  <div>
    <div class="e-breadcrumb">基础分类设置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-card-tabs">
            <div class="e-tabs-wrapper">
              <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">服务项目分类
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==1}" @click="switchTable(1)">商品分类
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==2}" @click="switchTable(2)">会员类型
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==4}" @click="switchTable(4)">员工级别
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==3}" @click="switchTable(3)">员工工种分类
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==5}" @click="switchTable(5)">项目部门设置
              </div>
<!--              <div class="e-tabs-item" :class="{'active':tabIndex==6}" @click="switchTable(6)">角色设置-->
<!--              </div>-->
              <div class="e-tabs-item" :class="{'active':tabIndex==7}" @click="switchTable(7)">用户设置
              </div>
            </div>
          </div>
          <TabSserviceItemCategory  v-if="tabIndex==0"/>
          <TabSkuCategory  v-if="tabIndex==1"/>
          <TabMemberType  v-if="tabIndex==2"/>
          <TabSoCategory  v-if="tabIndex==3"/>
          <TabUserGrade  v-if="tabIndex==4"/>
          <TabDepartment  v-if="tabIndex==5"/>
          <TabRole  v-if="tabIndex==6"/>
          <TabUser  v-if="tabIndex==7"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TabSserviceItemCategory from './si-category/siEntry'
  import TabSkuCategory from './sku-category/skuEntry'
  import TabMemberType from './member-type/mtEntry.vue'
  import TabUserGrade from './user-grade/ugEntry'
  import TabSoCategory from './so-category/soEntry'
  import TabDepartment from './department/entry'
  import TabRole from './role/entry'
  import TabUser from './user/entry'

  export default {
    data() {
      return {
        tabIndex:0,
        item: {},
      }
    },
    async created() {
    },
    methods: {

      switchTable(tabIndex) {
        this.tabIndex = tabIndex
      },

    },
    components: {
      TabSserviceItemCategory,
      TabSkuCategory,
      TabMemberType,
      TabUserGrade,
      TabSoCategory,
      TabDepartment,
      TabRole,
      TabUser,
    }
  }

</script>

<style scoped>
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
</style>
