<template>
  <el-dialog title="编辑" :visible.sync="editUserGrade" :close-on-click-modal="false" width="600px" :before-close="closeEvent">
    <el-form ref="form" label-width="130px">
      <el-form-item size="small" label="分类编号:" required>
        <el-input v-model="item.gradeNo" placeholder="请输入分类编号" maxlength="32" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="分类名称:" required>
        <el-input v-model="item.gradeName" placeholder="请输入分类名称" maxlength="64" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>

      <el-form-item size="small" label="工种分类:" required>
        <el-select size="small" style="width: 405px" clearable v-model="item.categoryId" placeholder="请选择">
          <el-option
            v-for="item in categoryList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeEvent">取 消</el-button>
        <el-button size="small" type="primary" @click="saveUserGrade">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        item: this.editItem,
        categoryList: [],
      }
    },
    props: {
      editItem: Object,
      editUserGrade: Boolean
    },
    created() {
      this.getCategoryList()
    },
    methods: {
      async getCategoryList() {
        let data = {
          categoryType: 3
        }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },
      closeEvent() {
        this.$emit('closeEug')
      },
      async saveUserGrade() {
        let data = {
          id: this.item.id,
          gradeNo: this.item.gradeNo,
          gradeName: this.item.gradeName,
          categoryId: this.item.categoryId,
        };
        if (!this.item.gradeNo) {
          this.$message({
            showClose: true,
            message: '请填写编号',
            type: 'error'
          });
          return
        }
        if (!this.item.gradeName) {
          this.$message({
            showClose: true,
            message: '请填写分类名称',
            type: 'error'
          });
          return
        }
        if (!this.item.categoryId) {
          this.$message({
            showClose: true,
            message: '请选择工种分类',
            type: 'error'
          });
          return
        }
        let res = await this.$post("/admin/save_bsUserGrade", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$emit('eugSuccess')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
