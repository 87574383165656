<template>
  <div>
    <div class="container" style="width:200px;margin-top:10px;padding:10px;height: 400px">
      <v-tree :nodes="nodes">
        <template slot-scope="{node}">
          <a href="javascript:;" @click.stop="addEvent(node)" class="v-tree-plus"></a>
          <a href="javascript:;" @click.stop="editEvent(node)" class="v-tree-edit"></a>
          <a href="javascript:;" @click.stop="removeEvent(node)" class="v-tree-remove"></a>
        </template>
      </v-tree>
    </div>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delDepartment">确 定</el-button>
        <el-button size="small" @click="delVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addSuccess"
            :parentName="parentName" :id="id"/>
    <Edit
      v-if="editVisible"
      :editVisible="editVisible"
      @closeEdit="closeEdit"
      @editSuccess="editSuccess"
      :editData="editData"
    />
  </div>
</template>

<script>
  import Insert from './insert'
  import Edit from './edit'
  import vTree from '@/components/common/v-tree/tree'
  import {dataFormat} from '../../../utils/main'

  import {getDepartmentList, addRoot, deleteDepartment} from '../../../api/sys/department'

  export default {
    data() {
      return {
        name: "",
        departmentCode: "",
        nodes: [],
        list: {},
        options: {
          data: {
            simpleData: {
              idKey: "id",
              pIdKey: "parentId",
            },
            key: {
              name: "departmentName",
              children: 'children'
            }
          }
        },
        id: '',
        parentName: '',
        addRootVisible: false,
        addVisible: false,
        editVisible: false,
        delVisible: false,
        editData: {}
      }
    },
    created() {
      this.searchEvent();
    },
    methods: {
      async searchEvent() {
        let res = await this.$get("/admin/getDepartmentList")
        let data = dataFormat(this.options, res)
        this.nodes = data.list
        this.list = data.map
      },

      //添加顶级机构
      async addRoot() {
        let res = await addRoot(this.name)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.addRootVisible = false
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },
      addRootEvent() {
        this.addRootVisible = true
      },

      addEvent(node) {
        this.addVisible = true;
        this.id = node.id
        this.parentName = node.departmentName
      },
      editEvent(node, nodes) {
        this.editData = {
          id: node.id,
          departmentCode: node.departmentCode,
          departmentName: node.departmentName,
          parentName: (this.list[node.parentId] ? this.list[node.parentId].departmentName : '无')
        };
        this.editVisible = true;
      },
      removeEvent(node) {
        this.departmentCode = node.departmentCode
        this.delVisible = true
      },
      async delDepartment() {
        let res = await deleteDepartment(this.departmentCode)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },
      addSuccess() {
        this.closeInsert();
        this.searchEvent();
      },

      closeInsert() {
        this.addVisible = false;
      },
      editSuccess() {
        this.closeEdit();
        this.searchEvent();
      },
      closeEdit() {
        this.editVisible = false;
      }
    },
    components: {
      vTree,
      Insert,
      Edit
    }
  }
</script>

<style scoped>
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
</style>
