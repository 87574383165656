<template>
  <el-dialog title="编辑" :close-on-click-modal="false" :visible.sync="editSoCategory" width="600px" :before-close="closeEvent">
    <el-form ref="form" label-width="130px">
      <el-form-item size="small" label="分类编号:" required>
        <el-input v-model="item.categoryNo" placeholder="请输入分类编号" maxlength="32" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="分类名称:" required>
        <el-input v-model="item.categoryName" placeholder="请输入分类名称" maxlength="64" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="工位:" required>
        <el-select v-model="item.station" style="width: 405px" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeEvent">取 消</el-button>
        <el-button size="small" type="primary" @click="saveCategory">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        item: this.editItem,
        options: [
          {
            name: '第一工位',
            value: '1',
          },
          {
            name: '第二工位',
            value: '2',
          }, {
            name: '项目销售',
            value: '3',
          }
        ],
      }
    },
    props: {
      editItem: Object,
      editSoCategory: Boolean
    },
    created() {
    },
    methods: {
      closeEvent() {
        this.$emit('closeEso')
      },
      async saveCategory() {
        let data = {
          id: this.item.id,
          categoryNo: this.item.categoryNo,
          categoryName: this.item.categoryName,
          station: this.item.station,
        };
        if (!this.item.categoryNo) {
          this.$message({
            showClose: true,
            message: '请填写分类编号',
            type: 'error'
          });
          return
        }
        if (!this.item.station) {
          this.$message({
            showClose: true,
            message: '请选择工位',
            type: 'error'
          });
          return
        }
        if (!this.item.categoryName) {
          this.$message({
            showClose: true,
            message: '请填写分类名称',
            type: 'error'
          });
          return
        }
        let res = await this.$post("/admin/save_bsCategory", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$emit('esoSuccess')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
