<template>
  <el-dialog title="新增" :close-on-click-modal="false" width="600px" :visible.sync="addUserGrade"
             :before-close="closeEvent">
    <el-form ref="form" label-width="130px">
      <el-form-item size="small" label="分类编号:" required>
        <el-input v-model="gradeNo" placeholder="请输入分类编号" maxlength="32" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="分类名称:" required>
        <el-input v-model="gradeName" placeholder="请输入分类名称" maxlength="64" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="工种分类:" required>
        <el-select size="small" style="width: 405px" clearable v-model="categoryId" placeholder="请选择">
          <el-option
            v-for="item in categoryList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="closeEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveUserGrade">确 定</el-button>
        </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        gradeNo: '',
        gradeName: '',
        categoryId: '',

        categoryList: [],
      }
    },
    props: {
      addUserGrade: Boolean
    },
    created() {
      this.getCategoryList();
    },
    methods: {
      async getCategoryList() {
        let data = {
          categoryType: 3
        }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },
      closeEvent() {
        this.$emit('closeAug')
      },
      async saveUserGrade() {
        let data = {
          gradeNo:this. gradeNo,
          gradeName:this. gradeName,
          categoryId:this. categoryId,
        };

        if (!this.gradeNo) {
          this.$message({
            showClose: true,
            message: '请填写编号',
            type: 'error'
          });
          return
        }
        if (!this.gradeName) {
          this.$message({
            showClose: true,
            message: '请填写分类名称',
            type: 'error'
          });
          return
        }
        if (!this.categoryId) {
          this.$message({
            showClose: true,
            message: '请选择工种分类',
            type: 'error'
          });
          return
        }
        let res = await this.$post("/admin/save_bsUserGrade", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$emit('augSuccess')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
