<template>
  <el-dialog title="编辑" :visible.sync="editMemberType" :close-on-click-modal="false" width="600px" :before-close="closeEvent">
    <el-form ref="form" label-width="130px">
      <el-form-item size="small" label="分类编号:" required>
        <el-input v-model="item.categoryNo" placeholder="请输入分类编号" maxlength="32" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="分类名称:" required>
        <el-input v-model="item.categoryName" placeholder="请输入分类名称" maxlength="64" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="累计消费:" required>
        <el-input v-model="item.cumulative" placeholder="请输入累计消费" maxlength="12" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeEvent">取 消</el-button>
        <el-button size="small" type="primary" @click="saveCategory">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        item: this.editItem,
      }
    },
    props: {
      editItem: Object,
      editMemberType: Boolean
    },
    created() {
    },
    methods: {
      closeEvent() {
        this.$emit('closeEmt')
      },
      async saveCategory() {
        let data = {
          id: this.item.id,
          categoryNo: this.item.categoryNo,
          categoryName: this.item.categoryName,
          cumulative: this.item.cumulative,
          categoryType: '2',
        };
        if (!this.item.categoryNo) {
          this.$message({
            showClose: true,
            message: '请填写分类编号',
            type: 'error'
          });
          return
        }
        if (!this.item.categoryName) {
          this.$message({
            showClose: true,
            message: '请填写分类名称',
            type: 'error'
          });
          return
        }
        if (!this.item.cumulative) {
          this.$message({
            showClose: true,
            message: '请填写累计消费',
            type: 'error'
          });
          return
        }
        let res = await this.$post("/admin/save_bsCategory", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$emit('emtSuccess')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
<style>
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
</style>
