<template>
    <el-dialog title="新增" :visible.sync="addVisible" :before-close="closeEvent" width="600px">
      <el-form ref="form" label-width="100px">
          <el-form-item size="small" label="上级名称:" required>
             <p v-text="parentName"></p>
          </el-form-item>
         <el-form-item label="名称:" required >
          <el-input size="small" v-model="departmentName"  maxlength="6"  style="width: 413px;" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="saveDepartment">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
    </el-dialog>
</template>
<script>
  import {saveDepartment} from '../../../api/sys/department'

  export default {
    data () {
        return {
            departmentName: '',
      }
    },
    props: {
        id: String,
        parentName: String,
      addVisible: Boolean
    },
    methods: {
      closeEvent () {
        this.$emit('closeInsert')
      },
      async saveDepartment () {
        let data = {
            departmentName: this.departmentName,
            parentId: this.id
        };
        let res = await saveDepartment(data);
        if (res.code==200){
            this.$message({
              showClose: true,
              message: res.message,
              type: 'success'
            });
            this.$emit('addSuccess')
        }else {
            this.$message({
                showClose: true,
                message: res.message,
                type: 'error'
            });
        }
      }
    }
  }
</script>

<style scoped>

</style>
