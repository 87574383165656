import axios from 'axios'
import {createSearchParams} from '../../utils/main'
import { Base_url} from '../../config/index'


//获取全部组织
function getDepartmentList(){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getAllDepartment',
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//添加顶级组织
function addRoot(name){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/save_root',
            params:{
                departmentName:name
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//添加组织
function saveDepartment(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/saveDepartment',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//更新
function updateDepartment(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/updateDepartment',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//删除权限
function deleteDepartment(departmentCode){
    return new Promise(resolve => {
        axios({
            method: 'POST',
            url: Base_url+'/admin/deleteDepartment',
            params:{
                departmentCode:departmentCode
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

export {
    getDepartmentList,
    addRoot,
    updateDepartment,
    deleteDepartment,
    saveDepartment
}
