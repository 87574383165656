import axios from 'axios'
import {createSearchParams} from '../../utils/main'
import { Base_url} from '../../config'

//保存用户角色
function saveUserRoleGroup(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/save_userRoleGroup',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//查找选择的用户对应的角色
function checkRoles(id){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/check_roles',
            data:createSearchParams({
                userId:id
            })
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

export {
    saveUserRoleGroup,
    checkRoles
}
