<template>
  <div>
    <div style="margin-bottom: 35px">
      <div class="handle-box">
        <span class="v-form-label">分类编号:</span>
        <el-input placeholder="分类编号" size="small" style="width:265px" v-model="categoryNo" clearable
                  class="handle-input mr10"></el-input>
        <span class="v-form-label">分类名称:</span>
        <el-input placeholder="分类名称" size="small" style="width:265px" v-model="categoryName" clearable
                  class="handle-input mr10"></el-input>
        <div style="margin-left: 230px">
          <el-row type="flex" justify="end">
            <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
            </el-button>
            <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
            </el-button>
          </el-row>
        </div>
      </div>
    </div>
    <div class="e-table-wrapper">
      <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
        <thead>
        <tr>
          <th width="130" class="th-l">分类编号</th>
          <th width="130" class="th-l">分类名称</th>
          <th width="130" class="th-l" >消费累计</th>
          <th width="130" class="th-l">创建时间</th>
          <th width="180" class="th-l">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
          <td class="td-l"><span>{{row.categoryNo}}</span></td>
          <td class="td-l">{{row.categoryName}}</td>
          <td class="td-l">
            {{row.cumulative}}
          </td>
          <td class="td-l"><span>{{row.createTime}}</span></td>
          <td class="td-l">
            <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
            <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :pager-count="7"
          layout="total, prev, pager, next,jumper"
          :total="pagination.count"
          :page-count="pagination.pages"
          :current-page="pagination.pageNumber"
        ></el-pagination>
      </div>
    </div>
    <!-- 会员类型的新增跟编辑 -->
    <Imt v-if="addMemberType" :addMemberType="addMemberType" @closeAmt="closeAmt" @amtSuccess="amtSuccess"/>
    <Emt v-if="editMemberType" :editMemberType="editMemberType" @closeEmt="closeEmt" @emtSuccess="emtSuccess"
         :editItem="item"/>
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMethod">确 定</el-button>
            </span>
    </el-dialog>
  </div>

</template>

<script>
  import Imt from './insertMemberType'
  import Emt from './editMemberType'
  export default {
    name: "mtEntry",
    data() {
      return {
        categoryNo: '',
        categoryName: '',
        categoryList: [],
        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        //会员类型
        addMemberType: false,
        editMemberType: false,

        delVisible: false,
        item: {},
      }
    },
    async created() {
      this.searchEvent();
      this.getCategoryList()
    },
    methods: {
      async getCategoryList() {
        let data = {
          categoryType: 3
        }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },

      searchEvent() {
        this.requestSearch()
      },


      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          categoryNo: this.categoryNo,
          categoryName: this.categoryName,
          categoryType: 2,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }

        let res = await this.$get("/admin/getBsCategoryForPage", data)
        if (res.code == 200) {
          this.list = res.dataSet.list;
          this.pagination = {
            count: res.dataSet.count,
            pageNumber: res.dataSet.pageNumber,
            pageSize: res.dataSet.pageSize,
            pages: res.dataSet.pages
          }
        } else {
          this.$message({
            showClose: true,
            message: dataSet.message,
            type: 'error'
          });
        }

      },


      addEvent() {
        this.addMemberType = true;
      },

      editEvent(index) {
        this.item = this.list[index]
        this.editMemberType = true;
      },

      async removeEvent(index) {
        this.item = this.list[index]
        this.delVisible = true;
      },

      //删除
      async delMethod() {
        let data = {
          id: this.item.id
        };

        let res = await this.$post("/admin/delete_bsCategory", data)

        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },

    //会员类型相关
    closeAmt() {
      this.addMemberType = false;
    },
    amtSuccess() {
      this.closeAmt();
      this.searchEvent();
    },
    closeEmt() {
      this.editMemberType = false;
    },
    emtSuccess() {
      this.closeEmt();
      this.searchEvent();
    },
  },
  components: {
    Imt,
      Emt,
  }
  }
</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
    .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
